<template>
  <v-card flat>
    <v-autocomplete
      :items="matchupMatrix"
      item-text="name"
      return-object
      v-model="selectedMMs"
      label="Filter Teams"
      multiple chips small-chips deletable-chips
      attach
    ></v-autocomplete>
    <v-data-table
      :headers="matchupHeaders"
      :items="matchupMatrix"
      dense
      hide-default-footer
      :items-per-page="-1"
    >
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ['matches', 'division'],
  data () {
    return {
      selectedMMs: []
    }
  },
  computed: {
    matchupMatrix () {
      const matches = this.matches
      const rows = this.division.activeTeams.map(t => {
        var obj = {
          name: t.name
        }
        this.division.activeTeams.filter(f => f.id !== t.id).forEach(ot => {
          const n = matches.filter(m => m.isBetweenTeamId([t.id, ot.id])).length
          obj[ot.name] = n
        })
        return obj
      })
      var tot = {}
      rows.forEach(r => {
        for (const key in r) {
          if (!tot[key]) tot[key] = 0
          tot[key] = tot[key] + r[key]
        }
      })
      tot.name = 'Total'
      rows.push(tot)
      return rows
    },
    matchupHeaders () {
      var cols = this.selectedMMs.length > 0 ? this.selectedMMs : this.matchupMatrix
      return [{
        text: 'Team',
        value: 'name',
        fixed: true
      }, ...cols.map(m => {
        return {
          text: m.name,
          value: m.name,
          align: 'center'
        }
      })]
    }

  }
}
</script>
